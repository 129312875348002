// deps
import { useRef } from "react";
import { useLocale } from "@react-aria/i18n";
import { useTimeFieldState } from "@react-stately/datepicker";
import { useTimeField } from "@react-aria/datepicker";
import { Box, FormLabel } from "@chakra-ui/react";

// components
import createCalendar from "./createCalendar";
import StyledField from "./StyledField";
import DateSegment from "./DateSegment";

function TimeField(props) {
  let { locale } = useLocale();
  let state = useTimeFieldState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = useRef();
  let { labelProps, fieldProps } = useTimeField(props, state, ref);

  return (
    <Box mt={2} flex={props.flex}>
      <FormLabel {...labelProps}>{props.label}</FormLabel>

      <StyledField {...fieldProps} ref={ref} display="inline-flex" pr={2}>
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </StyledField>
    </Box>
  );
}

export default TimeField;
