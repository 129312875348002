import { GregorianCalendar } from "@internationalized/date";

/**
 * Réduit la taille du bundle car nos applications n'utilisent que le calendrier grégorien.
 * @see https://react-spectrum.adobe.com/react-aria/useRangeCalendar.html#reducing-bundle-size
 * @param {string} identifier
 * @return {import("@internationalized/date").Calendar}
 */
export default function createCalendar(identifier) {
  switch (identifier) {
    case "gregory":
      return new GregorianCalendar();
    default:
      throw new Error(`Unsupported calendar ${identifier}`);
  }
}
