// deps
import { forwardRef, useRef } from "react";
import { useDatePickerState } from "@react-stately/datepicker";
import { useDatePicker } from "@react-aria/datepicker";
import { InputGroup, Box, InputRightElement, Icon } from "@chakra-ui/react";
import { IoBanOutline, IoCalendarOutline } from "react-icons/io5";

// components
import FieldButton from "./FieldButton";
import DateField from "./DateField";
import StyledField from "./StyledField";
import Popover from "./Popover";
import CalendarDateTime from "./CalendarDateTime";

const DatePicker = forwardRef(function DatePicker(props, ref) {
  let state = useDatePickerState(props);
  let inputGroupRef = useRef(null);
  let { groupProps, fieldProps, buttonProps, dialogProps, calendarProps } =
    useDatePicker(props, state, inputGroupRef);

  function handleFocus(event) {
    if (props.shouldAutoOpenPicker) {
      state.setOpen(true);
    }
    props?.onFocus?.(event);
  }

  return (
    <Box
      ref={ref}
      {...props.boxProps}
      position="relative"
      display="inline-flex"
      flexDirection="column">
      <InputGroup
        {...groupProps}
        ref={inputGroupRef}
        width="auto"
        display="inline-flex"
        onFocus={handleFocus}>
        <StyledField pr="4.5rem" w="100%" isOpen={state.isOpen}>
          <DateField {...fieldProps} />

          {state.validationState === "invalid" && (
            <Icon
              as={IoBanOutline}
              color="red.600"
              position="absolute"
              right="12"
            />
          )}
        </StyledField>

        <InputRightElement zIndex="auto">
          <FieldButton
            {...buttonProps}
            {...props.buttonProps}
            isPressed={state.isOpen}>
            <Icon as={IoCalendarOutline} />
          </FieldButton>
        </InputRightElement>
      </InputGroup>

      {state.isOpen && (
        <Popover
          {...dialogProps}
          isOpen={state.isOpen}
          onClose={() => state.setOpen(false)}>
          <CalendarDateTime state={state} calendarProps={calendarProps} />
        </Popover>
      )}
    </Box>
  );
});

export default DatePicker;
