// deps
import { parseDate, parseDateTime } from "@internationalized/date";
import PropTypes from "prop-types";

// components
import { forwardRef } from "react";
import Input from "./Input";

/**
 * Format a string to a date object.
 * @param {object} param0
 * @param {string} param0.value
 * @param {string} param0.granularity
 * @return {import("@internationalized/date").CalendarDateTime|import("@internationalized/date").CalendarDate}
 */
function fromStringToDateObject({ value, granularity }) {
  try {
    switch (granularity) {
      case "day":
      case "month":
        return parseDate(value);
      default:
        return parseDateTime(value);
    }
  } catch (e) {
    void 0;
  }
  return null;
}

/**
 * Format a date object to a string.
 * @param {object} param0
 * @param {typeof import("@internationalized/date").CalendarDateTime} param0.selectedDate
 * @return {string}
 */
function fromDateObjectToString({ selectedDate }) {
  return selectedDate.toString();
}

const DatePicker = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string} name
   * @property {string} [granularity]
   *
   * @param {import("@react-aria/calendar").AriaCalendarProps<import("@internationalized/date").DateValue> & Props} props
   */
  function DatePicker(props, ref) {
    const { name, value, granularity, onChange, ...otherProps } = props;

    function handleChange(selectedDate) {
      onChange({
        target: {
          name,
          value: fromDateObjectToString({ selectedDate }),
        },
      });
    }

    return (
      <Input
        {...otherProps}
        ref={ref}
        granularity={granularity}
        aria-label="Date picker"
        value={fromStringToDateObject({ value, granularity })}
        onChange={handleChange}
      />
    );
  },
);

DatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  granularity: PropTypes.oneOf(["day", "hour", "minute", "second"]),
  shouldCloseOnSelect: PropTypes.bool,
  shouldAutoOpenPicker: PropTypes.bool,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  granularity: "day",
  shouldCloseOnSelect: true,
  shouldAutoOpenPicker: true,
};

export default DatePicker;
