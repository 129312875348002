// deps
import { Select } from "@chakra-ui/react";
import { useDateFormatter } from "@react-aria/i18n";

function MonthSelector(props) {
  const { state } = props;

  let months = [];
  let formatter = useDateFormatter({
    month: "long",
    timeZone: state.timeZone,
  });

  // Format the name of each month in the year according to the
  // current locale and calendar system. Note that in some calendar
  // systems, such as the Hebrew, the number of months may differ
  // between years.
  let numMonths = state.focusedDate.calendar.getMonthsInYear(state.focusedDate);
  for (let i = 1; i <= numMonths; i++) {
    let date = state.focusedDate.set({ month: i });
    months.push(formatter.format(date.toDate(state.timeZone)));
  }

  let onChange = (e) => {
    let value = Number(e.target.value);
    let date = state.focusedDate.set({ month: value });
    state.setFocusedDate(date);
  };

  return (
    <Select
      size="sm"
      w={130}
      aria-label="Month"
      onChange={onChange}
      value={state.focusedDate.month}>
      {months.map((month, i) => (
        <option key={i} value={i + 1}>
          {month}
        </option>
      ))}
    </Select>
  );
}

export default MonthSelector;
