// deps
import { useRef } from "react";
import { FocusScope } from "@react-aria/focus";
import { useDialog } from "@react-aria/dialog";
import { useOverlay, useModal, DismissButton } from "@react-aria/overlays";
import { mergeProps } from "@react-aria/utils";
import { Box, useColorModeValue } from "@chakra-ui/react";

function Popover(props) {
  let ref = useRef();
  let { popoverRef = ref, isOpen, onClose, children, ...otherProps } = props;

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  let { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: false,
      isDismissable: true,
    },
    popoverRef,
  );

  let { modalProps } = useModal();
  let { dialogProps } = useDialog(otherProps, popoverRef);

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope>
      <Box
        {...mergeProps(overlayProps, modalProps, dialogProps)}
        ref={popoverRef}
        bgColor={bgColor}
        border="1px solid"
        borderColor={borderColor}
        borderRadius="md"
        position="absolute"
        zIndex="10"
        top="100%"
        boxShadow="lg"
        marginTop="1"
        padding="1rem"
        outline="none">
        {children}

        <DismissButton onDismiss={onClose} />
      </Box>
    </FocusScope>
  );
}

export default Popover;
