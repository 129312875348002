// deps
import { useRef } from "react";
import { useButton } from "@react-aria/button";
import { IconButton } from "@chakra-ui/react";

function CalendarIconButton(props) {
  const { icon } = props;
  let ref = useRef();
  let { buttonProps } = useButton(props, ref);
  return <IconButton {...buttonProps} ref={ref} size="sm" icon={icon} />;
}

export default CalendarIconButton;
