// deps
import { useRef } from "react";
import { useDateSegment } from "@react-aria/datepicker";
import { Box, useColorModeValue } from "@chakra-ui/react";

function DateSegment({ segment, state }) {
  let ref = useRef();
  let { segmentProps } = useDateSegment(segment, state, ref);

  const textColor = useColorModeValue(
    segment.isPlaceholder
      ? "gray.500"
      : !segment.isEditable
      ? "gray.600"
      : "black",
    segment.isPlaceholder
      ? "white"
      : !segment.isEditable
      ? "gray.600"
      : "white",
  );

  return (
    <Box
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        fontVariantNumeric: "tabular-nums",
        boxSizing: "content-box",
      }}
      minWidth={
        segment.maxValue != null && `${String(segment.maxValue).length}ch`
      }
      paddingX="0.5"
      textAlign="end"
      outline="none"
      rounded="md"
      color={textColor}
      _focus={{
        background: "blue.500",
        color: "white",
      }}>
      {segment.text}
    </Box>
  );
}

export default DateSegment;
