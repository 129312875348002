// deps
import { useIntl } from "react-intl";

// components
import Calendar from "./Calendar";
import TimeField from "./TimeField";

function CalendarDateTime(props) {
  const intl = useIntl();

  return (
    <>
      <Calendar {...props.calendarProps} />

      {["second", "minute"].includes(props.state.granularity) && (
        <TimeField
          label={intl.formatMessage({
            defaultMessage: "Heure",
          })}
          value={props.state.timeValue}
          onChange={props.state.setTimeValue}
        />
      )}
    </>
  );
}

export default CalendarDateTime;
